.work h1 {
  text-align: center;
  color: rgb(189, 55, 46);
  font-size: var(--s1);
  line-height: 1.2;
}

.group {
  display: flex;
  justify-content: center;
}

.work img {
  width: 15%;
  margin: 15px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 4px 9px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 4px 9px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 4px 9px -6px rgba(0, 0, 0, 0.75);
}

.work img:hover {
  transform: translateY(-3px);
  transition: all 0.2s ease;
}

#mobile {
  display: none;
}

@media (max-width: 1110px) {
  .work img {
    width: 20%;
    margin: 15px;
  }
}

@media (max-width: 650px) {
  #desktop {
    display: none;
  }
  #mobile {
    display: grid;
    grid-template-columns: auto auto;
  }
  .group img {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  .gallery {
    display: flex;
    justify-content: center;
  }
  .group {
    flex-direction: column;
  }
}
