* {
  margin: 0;
  padding: 0;
}

.nav {
  top: 0;
  right: 0;
  left: 0;
  background: white;
  color: rgb(189, 55, 46);
  z-index: 1;
}

.navbar {
  font-size: 23px;
  margin-left: 1em;
  margin-right: 1em;
}

.navbar-nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

a {
  text-decoration: none;
}

ul {
  margin-left: 100px;
}

.nav-item {
  margin: 0 0 0 2%;
}

.nav-item a {
  font-weight: 500;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgb(189, 55, 46);
  text-align: center;
}

.navbar-light:hover .navbar-nav:hover .nav-link.active:hover,
.navbar-light:hover .navbar-nav:hover .show:hover > .nav-link:hover {
  transform: scale(1.05);
}

.nav-link .active {
  color: rgb(189, 55, 46);
}

/* after toggle */
.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.active {
  color: rgb(189, 55, 46);
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #d3301a;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

.navbar-toggler.collapsed .toggler-icon {
  color: rgb(189, 55, 46);
  text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
}

@media (max-width: 650px) {
  .navbar {
    font-size: 23px;
    margin-left: 0.15em;
    margin-right: 0.15em;
  }
}
