* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.contactSection {
  margin: 5em 0 5em 0;
}

.contactSection h1 {
  text-align: center;
  color: rgb(189, 55, 46);
  font-size: var(--s1);
  line-height: 1.2;
}

.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}

.contactForm {
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15rem;
}

.form-container {
  width: 25vw;
}

.left {
  width: 25vw;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.right {
  padding: 0;
  margin: 0;
}

.left img {
  width: inherit;
  object-fit: cover;
  transform: scale(2.3);
}

.right .contact {
  display: flex;
  gap: 1rem;
  margin-right: 1rem;
}

.form,
.address {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0;
}

.form input,
textarea {
  width: 100%;
  outline: none;
  background: none;
  border: none;
  border-bottom: 2px solid #000;
  padding: 10px 0;
  margin: 5px 0;
}

.usersubmit input {
  background-color: #bd372e;
  color: white;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  width: min-content;
  padding: 15px;
  margin-bottom: 0;
  cursor: pointer;
  transition: 0.3s;
}

.usersubmit input:hover {
  background-color: #99312a;
}

.address {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

h4 {
  margin-bottom: 5px;
}

textarea {
  resize: none;
}

@media (max-width: 1110px) {
  .left {
    width: 40vw;
  }
  .right {
    width: 35vw;
  }
}

@media (max-width: 1000px) {
  .contactForm {
    display: block;
  }
  .left {
    width: 50vw;
    margin: 0 auto 2em auto;
  }
  .right {
    margin: 0 auto 0 auto;
    text-align: center;
  }
  .right form {
    width: 35vw;
  }
}

@media (max-width: 820px) {
  .left {
    width: 75vw;
  }
  .right .form-container {
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
  }
  .right {
    width: 100%;
    margin-left: auto;
  }

  .right .form {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .left {
    width: 90vw;
  }
  .right .form-container {
    margin-left: auto;
    margin-right: auto;
    width: 80vw;
  }
  .right {
    width: 100%;
    margin-left: auto;
  }

  .right .form {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .container {
    overflow: hidden;
  }
  .left {
    height: 20vh;
  }
  .contact {
    flex-direction: column;
  }
}
